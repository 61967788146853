export default defineNuxtRouteMiddleware(async () => {
  // Checking if the user's subscription is active
  try {
    await nextTick()
    const { data: userInfos } = await useUserInfos()
    if (userInfos.value !== null && userInfos.value.is_subscription_active === false) {
      return navigateTo('/unsubscribed')
    }
  } catch (e) {
    console.error(e)
    const { auth } = useSupabaseClient()
    await auth.signOut()
    return navigateTo('/login')
  }
})